<template>
  <div>
    <Columns>
        <Column class="mb-4">
          <h3 class="title is-4 mr-2 mb-1">
            Recent Projects
          </h3>
          <router-link :to="{
                path: '/projects'
              }"
            >
              Go to Projects
          </router-link>
        </Column>
    </Columns>


    <Columns isMobile isMultiline>
        <Column
          v-for="(project, index) in projects"
          :key="index"
          class="is-half-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd"
        >
            <router-link :to="{
                name: 'Project Detail',
                params: {
                  slug: project.path,
                  path: project.path_with_namespace
                }
              }"
            >

              <project-card :data="project" :random-color="true"></project-card>

            </router-link>
        </Column>
      </Columns>

  </div>
</template>

<script>
  import { toRefs } from 'vue'
  import { getProjectsAPI } from '@/use/useGitlabApi'
  import Columns from '@/components/ds/Columns.vue'
  import Column from '@/components/ds/Column.vue'
  import ProjectCard from '@/components/projects/card'

  export default {
  components: {
    // Box,
    Columns,
    Column,
    ProjectCard
  },
  setup () {
    const state = getProjectsAPI({ per_page: 8, order_by: 'updated_at' })

    return {
      ...toRefs(state),
    }
  }
}
</script>

<style lang="scss" scoped>

</style>